import request from '@/utils/request'

export function getVipTypes() {
    return request({
        url: '/admin/vip/type',
        method: 'get'
    })
}

export function createVip(data) {
    return request({
        url: '/admin/vip/create',
        method: 'post',
        data
    })
} 